import { faCheckDouble, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notification } from "antd";
import Button from "antd/lib/button";
import React from "react";
import NotificationSHA256 from "../../components/NotificationSHA256";
import {
  DOWNLOAD_OS_MAPPING,
  DOWNLOAD_PACKAGING_MAPPING,
  OPENCHROM_FILE_TEMPLATE,
  OPENCHROM_WINDOWS_FILE_TEMPLATE,
  OS,
} from "../../constants/links";
import { HashSize } from "../../types/download";
import { getDownloadExeLink, getDownloadLinkByOS } from "../../utils/link";

interface Props {
  hashSizes: HashSize[];
  version: string;
  url: string;
}

const OpenChromDownloadWindow: React.FC<Props> = (props) => {
  const [api, contextHolder] = notification.useNotification();
  const getFileSize = (fileName: string) => {
    fileName = fileName || getFileName();
    const file = props.hashSizes.find((file) => file.name === fileName);
    return file ? file.size : "";
  };

  const getFileHash = (fileName: string) => {
    fileName = fileName || getFileName();
    const file = props.hashSizes.find((file) => file.name === fileName);
    return file ? file.hash : "";
  };

  const getFileName = (type: "setup" | "portable" = "portable") => {
    return OPENCHROM_WINDOWS_FILE_TEMPLATE
      .replace(/{version}/g, props.version)
      .replace(/{type}/g, type);
  };

  const openDownloadExe = async (type: "setup" | "portable" = "portable") => {
    const fileName = getFileName(type);
    api.open({
      message: `SHA256 Hash for ${fileName}`,
      icon: <FontAwesomeIcon icon={faCheckDouble} />,
      description: <NotificationSHA256 hash={getFileHash(fileName)} />,
      duration: 0
    });
    window.open(getDownloadExeLink(props.url, props.version, type), '_blank');
  };

  return (
    // <div className="download-area h-auto p-4">
    <>
      {contextHolder}
      <div className="flex flex-col lg:flex-row justify-around">
        {!!getFileHash(getFileName("setup")) && (
          <div className="text-align-start text-lg pt-4" style={{ flexBasis: "50%" }}>
            <p className="overflow-wrap-breakword flex lg:justify-center">
              The OpenChrom installer requires admin rights.
            </p>
            <div className="flex flex-col">
              <div className="ant-btn-group btn-download h-auto lg:m-4 m-1 text-lg justify-center">
                <Button
                  type="default"
                  key="k1"
                  className="shadow text-lg flex h-auto p-4 mr-2"
                  style={{ color: "white" }}
                  onClick={() => openDownloadExe("setup")}
                >
                  <div>
                    <span className="font-semibold">Download installer</span>
                    <div className="overflow-wrap-anywhere">
                      <span className="font-semibold">File name:</span>{" "}
                      <span>{getFileName("setup")}</span>
                    </div>
                    <div className="overflow-wrap-anywhere">
                      <span className="font-semibold">Size:</span>{" "}
                      <span>
                        {getFileSize(getFileName("setup"))}
                      </span>
                    </div>
                  </div>
                  <div className="my-auto ml-4">
                    <FontAwesomeIcon size="2x" icon={faDownload} />
                  </div>
                </Button>
                {/* <MarketplaceButton /> */}
              </div>
            </div>
          </div>
        )}
        <div className="text-align-start text-lg pt-4" style={{ flexBasis: "50%" }}>
          <p className="overflow-wrap-breakword flex lg:justify-center">
            OpenChrom is available as a self-extracting file
          </p>
          <p className="flex justify-center">
            <div className="ant-btn-group btn-download  h-auto lg:m-4 m-1 text-lg">
              <Button
                type="default"
                key="k2"
                className="shadow text-lg flex h-auto p-4"
                style={{ color: "white" }}
                onClick={() => openDownloadExe("portable")}
              >
                <div>
                  <span className="font-semibold">Download portable file</span>
                  <div className="overflow-wrap-anywhere">
                    <span className="font-semibold">File name:</span>{" "}
                    <span>{getFileName()}</span>
                  </div>
                  <div className="overflow-wrap-anywhere">
                    <span className="font-semibold">Size:</span>{" "}
                    <span>{getFileSize(getFileName())}</span>
                  </div>
                </div>
                <div className="my-auto ml-4">
                  <FontAwesomeIcon size="2x" icon={faDownload} />
                </div>
              </Button>
            </div>
          </p>
        </div>
      </div>
      <div className="text-align-start text-lg">
        <p className="overflow-wrap-breakword">
          And via community repositories
        </p>
      </div>
      <div className="text-align-start text-lg ">
        <p className="overflow-wrap-breakword">
          <a href="https://community.chocolatey.org/packages/openchrom">
            Chocolatey
          </a>:
        </p>
        <p className="lg:mt-4 mt-1">
          <code className="terminal">
            <span className="terminal__prompt">&gt;</span> choco install
            openchrom
          </code>
        </p>
      </div>
      <div className="text-align-start text-lg mt-4">
        <p className="overflow-wrap-breakword">
          Also available from Microsoft
        </p>
        <p className="lg:mt-4 mt-1">
          <code className="terminal">
            <span className="terminal__prompt">&gt;</span> winget install Lablicate.OpenChrom
          </code>
        </p>
      </div>
    </>
    // </div> 
  );
};

export default OpenChromDownloadWindow;
